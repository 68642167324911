import React from "react";
import SideBarProfile from "../../Components/SideBarProfile/SideBarProfile";

const Profile = () => {
  return (
    <div>
      <SideBarProfile />
    </div>
  );
};

export default Profile;
