import React from "react";
import "./ReportsInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

const ReportsInitial = () => {
  const handlePrint = () => {
    window.print();
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="reportsInitialMainDiv">
        <div className="printBtn">
          <button className="btn btn-md btn-success" onClick={handlePrint}>
            Print Reports
          </button>
        </div>
        <div className="loansReport">
          <table class="table table-hover">
            <caption className="green">My Loans</caption>

            <thead>
              <tr>
                <th scope="col" className="tableHeader">
                  Serial
                </th>
                <th scope="col" className="tableHeader">
                  Loans Details
                </th>
                <th scope="col" className="tableHeader">
                  Next Payment
                </th>
                <th scope="col" className="tableHeader">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>
                  Reference :42409268 <br />
                  Loan type :Gold <br />
                  Loan Plan :24 month/s [ 5%,2 ] <br />
                  Amount :5500000 <br />
                  Total Payable Amount :5,775,000.00 Monthly <br />
                  Payable Amount: 240,625.00 <br />
                  Overdue Payable Amount: 4,812.50 <br />
                </td>
                <td>
                  Date: Apr 23, 2024 <br />
                  Monthly amount:240,625.00 <br />
                  Penalty :4812.5 <br />
                  Payable Amount :245,437.50 <br />
                </td>
                <td className="pending">Pending</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>
                  Reference :42409268 <br />
                  Loan type :Gold <br />
                  Loan Plan :24 month/s [ 5%,2 ] <br />
                  Amount :5500000 <br />
                  Total Payable Amount :5,775,000.00 Monthly <br />
                  Payable Amount: 240,625.00 <br />
                  Overdue Payable Amount: 4,812.50 <br />
                  Date Released: Mar 23, 2024
                </td>
                <td>
                  Date: Apr 23, 2024 <br />
                  Monthly amount:240,625.00 <br />
                  Penalty :4812.5 <br />
                  Payable Amount :245,437.50 <br />
                </td>
                <td className="released">Released</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>
                  Reference :42409268 <br />
                  Loan type :Gold <br />
                  Loan Plan :24 month/s [ 5%,2 ] <br />
                  Amount :5500000 <br />
                  Total Payable Amount :5,775,000.00 Monthly <br />
                  Payable Amount: 240,625.00 <br />
                  Overdue Payable Amount: 4,812.50 <br />
                  Date Released: Mar 23, 2024
                </td>
                <td>
                  Date: Apr 23, 2024 <br />
                  Monthly amount:240,625.00 <br />
                  Penalty :4812.5 <br />
                  Payable Amount :245,437.50 <br />
                </td>
                <td className="released">Released</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="sharesReport">
          <table class="table">
            <caption className="green">My Shares</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Serial
                </th>
                <th className="headingTable" scope="col">
                  Type
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
                <th className="headingTable" scope="col">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Redeemable</td>
                <td>430</td>
                <td>4114.7</td>
              </tr>

              <tr>
                <th scope="row">1</th>
                <td>Redeemable</td>
                <td>430</td>
                <td>4114.7</td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>Redeemable</td>
                <td>430</td>
                <td>4114.7</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="guarantorsReport">
          <table class="table">
            <caption className="green">My Guarantors</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Reference
                </th>
                <th className="headingTable" scope="col">
                  Name
                </th>
                <th className="headingTable" scope="col">
                  Loan Type
                </th>
                <th className="headingTable" scope="col">
                  Loan Plan
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">42409268</th>
                <td>Mark</td>
                <td>Gold</td>
                <td>24 month/s [ 5%,2 ]</td>
                <td>5,500,000</td>
              </tr>

              <tr>
                <th scope="row">42409268</th>
                <td>Mark</td>
                <td>Gold</td>
                <td>24 month/s [ 5%,2 ]</td>
                <td>5,500,000</td>
              </tr>
              <tr>
                <th scope="row">42409268</th>
                <td>Mark</td>
                <td>Gold</td>
                <td>24 month/s [ 5%,2 ]</td>
                <td>5,500,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="guaranteesReport">
          <table class="table">
            <caption className="green">Guarantees</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Reference
                </th>
                <th className="headingTable" scope="col">
                  Name
                </th>
                <th className="headingTable" scope="col">
                  Loan Type
                </th>
                <th className="headingTable" scope="col">
                  Loan Plan
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">42409268</th>
                <td>Mark</td>
                <td>Gold</td>
                <td>24 month/s [ 5%,2 ]</td>
                <td>5,500,000</td>
              </tr>

              <tr>
                <th scope="row">42409268</th>
                <td>Mark</td>
                <td>Gold</td>
                <td>24 month/s [ 5%,2 ]</td>
                <td>5,500,000</td>
              </tr>
              <tr>
                <th scope="row">42409268</th>
                <td>Mark</td>
                <td>Gold</td>
                <td>24 month/s [ 5%,2 ]</td>
                <td>5,500,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="paymentsReport">
          <table class="table">
            <caption className="green">My payments</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Reference
                </th>
                <th className="headingTable" scope="col">
                  Payment For
                </th>
                <th className="headingTable" scope="col">
                  Method
                </th>
                <th className="headingTable" scope="col">
                  Date
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">4XFJL2Y</th>
                <td>Fine</td>
                <td>Mpesa</td>
                <td>25/10/2024</td>
                <td>90,000</td>
              </tr>

              <tr>
                <th scope="row">4XFJL2Y</th>
                <td>Fine</td>
                <td>Mpesa</td>
                <td>25/10/2024</td>
                <td>90,000</td>
              </tr>
              <tr>
                <th scope="row">4XFJL2Y</th>
                <td>Fine</td>
                <td>Mpesa</td>
                <td>25/10/2024</td>
                <td>90,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};

export default ReportsInitial;
