import React from "react";
import "./Loans.css";
import SideBarLoan from "../../Components/SideBarLoan/SideBarLoan";

const Loans = () => {
  return (
    <>
      <SideBarLoan />;
    </>
  );
};

export default Loans;
