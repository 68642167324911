import React from "react";
import "./SharesTable.css";

const SharesTable = () => {
  return (
    <div className="tableDiv">
      <table class="table">
        <caption>My Shares</caption>
        <thead>
          <tr>
            <th className="headingTable" scope="col">
              Amount
            </th>
            <th className="headingTable" scope="col">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>430</td>
            <td>4114.7</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SharesTable;
