import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "../../Components/LoginForm/LoginForm";
import Pic from "../../Assets/Images/horse.jpg";
import "./Login.css";

const Login = () => {
  return (
    <div className="main">
      <div className="pic">
        <img className="picture" src={Pic} alt="picha" />
      </div>
      <div className="form">
        <div className="formcontent">
          <LoginForm />
          <div className="loginFooter mt-2">
            NOID MICROFINANCE&copy;2024 |&nbsp;
            <Link className="loginFooterLink" to="/Constitution">
              constitution
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
