import React from "react";
import "./Card.css";

const Card = (props) => {
  return (
    <div className="cord">
      <div>
        <label className="cardText">{props.name}</label>
      </div>
      <div>
        <label className=" cardTextInner">{props.number}</label>
      </div>
    </div>
  );
};

export default Card;
