import React, { useState } from "react";

import UserContext from "./UserContext";

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <UserContext.Provider
      value={{ user, setUser, phoneNumber, setPhoneNumber }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
