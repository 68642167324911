import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./GuaranteeInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import UserContext from "../../contexts/UserContext";

const GuaranteeInitial = () => {
  const [guaranteeRequests, setGuaranteeRequests] = useState([]);
  const [myGuarantors, setMyGuarantors] = useState([]);
  const [myGuarantees, setMyGuarantees] = useState([]);
  const [registrationNumber, setRegistrationNumber] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      fetchRegistrationNumber();
    }
  }, [user]);

  useEffect(() => {
    if (registrationNumber) {
      fetchGuaranteeRequests();
      fetchMyGuarantors();
      fetchMyGuarantees();
      fetchRegistrationNumber();
    }
  }, [registrationNumber]);

  const fetchRegistrationNumber = async () => {
    try {
      const nameData = {
        name: user,
      };
      const response = await axios.post(
        " https://kiamabackend.mathenoid.com/api/registration-number",
        nameData
      );
      const data = response.data;
      if (data.success) {
        setRegistrationNumber(data.registration_number);
        console.log(registrationNumber);
      }
    } catch (error) {
      console.error("Error fetching registration number:", error);
    }
  };

  const fetchGuaranteeRequests = async () => {
    try {
      const response = await axios.get(
        ` https://kiamabackend.mathenoid.com/api/guarantee-requests/${registrationNumber}`
      );
      const data = response.data;
      if (data.success) {
        setGuaranteeRequests(data.guaranteeRequests);
      }
    } catch (error) {
      console.error("Error fetching guarantee requests:", error);
    }
  };

  const fetchMyGuarantors = async () => {
    try {
      const response = await axios.get(
        ` https://kiamabackend.mathenoid.com/api/my-guarantors/${registrationNumber}`
      );
      const data = response.data;
      if (data.success) {
        setMyGuarantors(data.myGuarantors);
      }
    } catch (error) {
      console.error("Error fetching my guarantors:", error);
    }
  };

  const fetchMyGuarantees = async () => {
    try {
      const response = await axios.get(
        ` https://kiamabackend.mathenoid.com/api/my-guarantees/${registrationNumber}`
      );
      const data = response.data;
      if (data.success) {
        setMyGuarantees(data.myGuarantees);
      }
    } catch (error) {
      console.error("Error fetching my guarantees:", error);
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainGuaranteeDiv">
        {/* Guarantee Requests */}
        <div className="guarantorRequests">
          <table className="table">
            <caption className="green">Guarantee requests</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Name
                </th>
                <th className="headingTable" scope="col">
                  Loan Type
                </th>
                <th className="headingTable" scope="col">
                  Loan Plan
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
                <th className="headingTable">Action</th>
              </tr>
            </thead>
            <tbody>
              {guaranteeRequests.map((request) => (
                <tr key={request.id}>
                  <td>{request.loanee}</td>
                  <td>{request.loantype}</td>
                  <td>{`${request.loanplan_duration} months [ ${request.loanplan_interest}%, ${request.loanplan_penalty} ]`}</td>
                  <td>{request.amount}</td>
                  <td>
                    <div className="action">
                      <button className="btn btn-small btn-success">
                        Accept
                      </button>
                      <button className="btn btn-small btn-danger">
                        Reject
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        {/* My Guarantors */}
        <div className="myGuarantors">
          <table className="table">
            <caption className="green">My Guarantors</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Loan Type
                </th>
                <th className="headingTable" scope="col">
                  Loan Plan
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
                <th className="headingTable" scope="col">
                  Guarantors
                </th>
              </tr>
            </thead>
            <tbody>
              {myGuarantors.map((loan) => (
                <tr key={loan.serial}>
                  <td>{loan.loantype}</td>
                  <td>{loan.loanplan}</td>
                  <td>{loan.amount}</td>
                  <td>{loan.guarantors}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        {/* My Guarantees */}
        <div className="myGuarantees">
          <table className="table">
            <caption className="green">My Guarantees</caption>
            <thead>
              <tr>
                <th className="headingTable" scope="col">
                  Name
                </th>
                <th className="headingTable" scope="col">
                  Loan Type
                </th>
                <th className="headingTable" scope="col">
                  Loan Plan
                </th>
                <th className="headingTable" scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {myGuarantees.map((loan) => (
                <tr key={loan.serial}>
                  <td>{loan.loanee}</td>
                  <td>{loan.loantype}</td>
                  <td>{loan.loanplan}</td>
                  <td>{loan.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};

export default GuaranteeInitial;
