import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./LoansTable.css";
import UserContext from "../../contexts/UserContext";

// Utility function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const LoansTable = () => {
  const [loans, setLoans] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await axios.get(
          ` https://kiamabackend.mathenoid.com/api/member-loans/${user}`
        );
        setLoans(response.data.loans);
      } catch (error) {
        console.error("Error fetching loans:", error);
      }
    };

    fetchLoans();
  }, [user]);

  return (
    <div className="loansMain">
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col" className="tableHeader">
                Serial
              </th>
              <th scope="col" className="tableHeader">
                Loans Details
              </th>
              <th scope="col" className="tableHeader">
                Due Date
              </th>
              <th scope="col" className="tableHeader">
                Comply
              </th>
              <th scope="col" className="tableHeader">
                Completed
              </th>
              <th scope="col" className="tableHeader">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loans.map((loan, index) => (
              <tr key={loan.serial}>
                <th scope="row">{index + 1}</th>
                <td>
                  Reference :{loan.serial} <br />
                  Loan type :{loan.loantype} <br />
                  Loan Plan :{loan.loanplan} <br />
                  Amount :{loan.amount} <br />
                  Date: {formatDate(loan.date)} <br />
                  {/* Due Date: {formatDate(loan.duedate)} <br /> */}
                </td>
                <td>
                  {/* Assuming you have next payment details in the loan object */}
                  {formatDate(loan.duedate)} <br />
                  {/* Add other payment details if available */}
                </td>
                <td>{loan.comply}</td>
                <td>{loan.completed}</td>
                <td className={loan.completed ? "completed" : "pending"}>
                  {loan.completed ? "Completed" : "Pending"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoansTable;
