import React, { useState, useEffect } from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";

const Repository = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(
          " https://kiamabackend.mathenoid.com/api/repository"
        );
        setItems(response.data);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await axios.get(fileUrl, { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="container mt-4">
      {items.length > 0 ? (
        <Table striped hover>
          <thead>
            <tr>
              <th>Serial</th>
              <th>Date</th>
              <th>Item</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Download {item.file_url.split("/").pop()}
                      </Tooltip>
                    }
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload(
                          ` https://kiamabackend.mathenoid.com${item.file_url}`,
                          item.file_url.split("/").pop()
                        );
                      }}
                      className="text-decoration-none"
                    >
                      {item.file_url.split("/").pop()}
                    </a>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-5 text-warning">
          <h4>No records yet!</h4>
        </div>
      )}
    </div>
  );
};

export default Repository;
