import React from "react";
import "./TermsConstitution.css";

const Constitution = () => {
  return (
    <div className="constitution-container">
      <h1 className="heading">Constitution of NOID MICROFINANCE</h1>

      <p className="section">
        <b className="section-heading">Preamble</b>
        <br />
        We, the members of NOID MICROFINANCE, in order to provide financial
        services to underserved communities, promote financial inclusion, and
        support economic development, do hereby establish this Constitution for
        the governance and operation of our institution.
      </p>

      <p className="section">
        <b className="section-heading">Article I: Name and Purpose</b>
        <br />
        <b>1.1 Name</b>
        <br />
        The name of the organization shall be NOID MICROFINANCE (hereinafter
        referred to as "the Institution").
        <br />
        <b>1.2 Purpose</b>
        <br />
        The purpose of the Institution is to:
        <ul>
          <li>
            Provide microloans, savings, and other financial services to
            individuals and small businesses in underserved communities.
          </li>
          <li>Promote financial literacy and education.</li>
          <li>Support economic empowerment and development.</li>
        </ul>
      </p>

      <p className="section">
        <b className="section-heading">Article II: Membership</b>
        <br />
        <b>2.1 Eligibility</b>
        <br />
        Membership is open to individuals and organizations that support the
        mission and objectives of the Institution.
        <br />
        <b>2.2 Application</b>
        <br />
        Prospective members must submit an application to the Board of
        Directors. Membership approval is at the discretion of the Board.
        <br />
        <b>2.3 Rights and Responsibilities</b>
        <br />
        Members have the right to attend meetings, vote on matters presented to
        the membership, and participate in programs and services. Members are
        responsible for upholding the principles and contributing to the
        sustainability of the Institution.
      </p>

      <p className="section">
        <b className="section-heading">Article III: Governance</b>
        <br />
        <b>3.1 Board of Directors</b>
        <br />
        The Institution shall be governed by a Board of Directors (hereinafter
        referred to as "the Board").
        <br />
        <b>3.2 Composition</b>
        <br />
        The Board shall consist of [number] members, including the Chairperson,
        Vice-Chairperson, Secretary, Treasurer, and other directors as needed.
        <br />
        <b>3.3 Election and Term</b>
        <br />
        Board members shall be elected by the membership at the Annual General
        Meeting (AGM) for a term of [number] years. Board members may serve a
        maximum of [number] consecutive terms.
        <br />
        <b>3.4 Powers and Duties</b>
        <br />
        The Board shall oversee the management and operations of the
        Institution, set strategic direction, approve budgets, and ensure
        compliance with legal and regulatory requirements.
      </p>

      <p className="section">
        <b className="section-heading">Article IV: Meetings</b>
        <br />
        <b>4.1 Annual General Meeting (AGM)</b>
        <br />
        The AGM shall be held annually to elect Board members, review the annual
        report, approve financial statements, and discuss other important
        matters.
        <br />
        <b>4.2 Board Meetings</b>
        <br />
        The Board shall meet at least [number] times per year. Special meetings
        may be called by the Chairperson or a majority of Board members.
        <br />
        <b>4.3 Quorum</b>
        <br />A quorum for the AGM and Board meetings shall be a majority of the
        members present.
      </p>

      <p className="section">
        <b className="section-heading">Article V: Financial Management</b>
        <br />
        <b>5.1 Fiscal Year</b>
        <br />
        The fiscal year of the Institution shall begin on [date] and end on
        [date].
        <br />
        <b>5.2 Financial Oversight</b>
        <br />
        The Board shall ensure proper financial management, including the
        preparation and approval of an annual budget, financial statements, and
        the appointment of an independent auditor.
        <br />
        <b>5.3 Banking</b>
        <br />
        All funds of the Institution shall be deposited in accounts designated
        by the Board. Authorized signatories for banking transactions shall be
        determined by the Board.
      </p>

      <p className="section">
        <b className="section-heading">Article VI: Amendments</b>
        <br />
        <b>6.1 Proposal of Amendments</b>
        <br />
        Amendments to this Constitution may be proposed by the Board or by a
        petition signed by at least [number] members.
        <br />
        <b>6.2 Approval of Amendments</b>
        <br />
        Amendments must be approved by a two-thirds majority of members present
        at the AGM or a special meeting called for that purpose.
      </p>

      <p className="section">
        <b className="section-heading">Article VII: Dissolution</b>
        <br />
        <b>7.1 Dissolution Procedure</b>
        <br />
        In the event of dissolution, the assets of the Institution shall be
        distributed to organizations with similar objectives, as determined by
        the Board and approved by the membership.
      </p>

      <p className="section">
        <b className="section-heading">Article VIII: Miscellaneous</b>
        <br />
        <b>8.1 Non-Discrimination</b>
        <br />
        The Institution shall not discriminate on the basis of race, color,
        religion, gender, sexual orientation, national origin, age, disability,
        or any other characteristic protected by law.
        <br />
        <b>8.2 Conflicts of Interest</b>
        <br />
        Board members and staff shall disclose any potential conflicts of
        interest and refrain from participating in decisions where a conflict
        exists.
      </p>

      <p className="section">
        <b className="section-heading">Adoption</b>
        <br />
        This Constitution was adopted by the membership on [date].
      </p>

      <p className="signatures">
        _____________________________
        <br />
        [Name], Chairperson
        <br />
        <br />
        _____________________________
        <br />
        [Name], Secretary
        <br />
        <br />
        _____________________________
        <br />
        Date
      </p>
    </div>
  );
};

export default Constitution;
