import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserContext from "../../contexts/UserContext";
import "./LoanApplicationModal.css"; // Import custom CSS

const LoanApplicationModal = ({ show, handleClose }) => {
  const [loanPlans, setLoanPlans] = useState([]);
  const [loanTypes, setLoanTypes] = useState([]);
  const [formData, setFormData] = useState({
    loanType: "",
    loanPlan: "",
    amount: "",
    guarantors: [
      { registration_number: "" },
      { registration_number: "" },
      { registration_number: "" },
    ],
  });
  const [loanDetails, setLoanDetails] = useState({
    totalPayable: 0,
    monthlyPayable: 0,
    penalty: 0,
  });
  const [guarantorErrors, setGuarantorErrors] = useState([false, false, false]);

  const { user } = useContext(UserContext); // Assuming user context provides the user name

  useEffect(() => {
    const fetchLoanPlans = async () => {
      try {
        const response = await axios.get(
          " https://kiamabackend.mathenoid.com/api/loan-plans"
        );
        setLoanPlans(response.data.loanPlans);
        console.log("Fetched Loan Plans:", response.data.loanPlans);
      } catch (error) {
        console.error("Error fetching loan plans:", error);
      }
    };

    const fetchLoanTypes = async () => {
      try {
        const response = await axios.get(
          " https://kiamabackend.mathenoid.com/api/loan-types"
        );
        setLoanTypes(response.data.loanTypes);
        console.log("Fetched Loan Types:", response.data.loanTypes);
      } catch (error) {
        console.error("Error fetching loan types:", error);
      }
    };

    fetchLoanPlans();
    fetchLoanTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGuarantorChange = async (e, index) => {
    const { name, value } = e.target;
    const guarantors = [...formData.guarantors];
    guarantors[index][name] = value;
    setFormData({
      ...formData,
      guarantors,
    });

    // Verify the guarantor
    try {
      const response = await axios.get(
        ` https://kiamabackend.mathenoid.com/api/verify-member/${value}`
      );
      const errors = [...guarantorErrors];
      errors[index] = !response.data.success;
      setGuarantorErrors(errors);

      // Check for duplicate registration numbers
      const registrationNumbers = guarantors
        .map((g) => g.registration_number)
        .filter(Boolean);
      const hasDuplicates =
        new Set(registrationNumbers).size !== registrationNumbers.length;
      if (hasDuplicates) {
        errors[index] = true;
      }
      setGuarantorErrors(errors);
    } catch (error) {
      console.error("Error verifying member:", error);
    }
  };

  const calculateLoan = async () => {
    const selectedLoanPlan = loanPlans.find(
      (plan) => plan.id === parseInt(formData.loanPlan)
    );

    console.log("Selected Loan Plan for Calculation:", selectedLoanPlan);

    if (!selectedLoanPlan) {
      alert("Please select a valid loan plan.");
      return;
    }

    try {
      const response = await axios.post(
        " https://kiamabackend.mathenoid.com/api/calculate-loan",
        {
          amount: Number(formData.amount),
          loanPlanId: selectedLoanPlan.id,
        }
      );
      setLoanDetails(response.data);
    } catch (error) {
      console.error("Error calculating loan:", error);
      alert("Error calculating loan. Please try again.");
    }
  };

  const applyLoan = async () => {
    const selectedLoanPlan = loanPlans.find(
      (plan) => plan.id === parseInt(formData.loanPlan)
    );
    const selectedLoanType = loanTypes.find(
      (type) => type.name === formData.loanType
    );

    console.log("Selected Loan Plan for Application:", selectedLoanPlan);
    console.log("Selected Loan Type for Application:", selectedLoanType);

    if (!selectedLoanPlan || !selectedLoanType) {
      alert("Please select a valid loan plan and loan type.");
      return;
    }

    // Ensure all guarantors are valid
    if (guarantorErrors.some((error) => error)) {
      alert("Please enter valid registration numbers for all guarantors.");
      return;
    }

    // Concatenate guarantors into a single string
    const guarantorsString = formData.guarantors
      .map((g) => g.registration_number)
      .filter(Boolean) // Remove any empty strings
      .join(" ");

    const loanApplicationData = {
      loanee: user,
      guarantors: guarantorsString,
      amount: Number(formData.amount),
      loanType: selectedLoanType.name,
      loanPlanDuration: Number(selectedLoanPlan.period),
      loanPlanInterest: Number(selectedLoanPlan.interest),
      loanPlanPenalty: Number(selectedLoanPlan.penalty),
    };

    console.log("Loan application data:", loanApplicationData);

    try {
      const response = await axios.post(
        " https://kiamabackend.mathenoid.com/api/apply-loan",
        loanApplicationData
      );

      console.log("Loan application response:", response.data);

      if (response.data.success) {
        alert("Loan application submitted successfully!");
        handleClose();
      } else {
        alert(`Error: ${response.data.errors}`);
      }
    } catch (error) {
      console.error("Error applying loan:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
        alert(
          `Error: ${error.response.data.errors || "An unknown error occurred"}`
        );
      } else {
        alert(
          "An error occurred while submitting the loan application. Please try again."
        );
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Loan Application</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="loanType">
            <Form.Label>Loan Type</Form.Label>
            <Form.Control
              as="select"
              name="loanType"
              value={formData.loanType}
              onChange={handleChange}
            >
              <option value="">Select Loan Type</option>
              {loanTypes.map((type) => (
                <option key={type.name} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="loanPlan">
            <Form.Label>Loan Plan</Form.Label>
            <Form.Control
              as="select"
              name="loanPlan"
              value={formData.loanPlan}
              onChange={handleChange}
            >
              <option value="">Select Loan Plan</option>
              {loanPlans.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.period} months, {plan.interest}% interest,{" "}
                  {plan.penalty}% penalty
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="amount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
          </Form.Group>

          <Row>
            {formData.guarantors.map((guarantor, index) => (
              <Col key={index} md={4}>
                <Form.Group controlId={`guarantorRegistrationNumber${index}`}>
                  <Form.Label>
                    Guarantor {index + 1} Registration Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="registration_number"
                    required
                    value={guarantor.registration_number}
                    onChange={(e) => handleGuarantorChange(e, index)}
                    isInvalid={guarantorErrors[index]}
                  />
                  <Form.Control.Feedback type="invalid">
                    Invalid or duplicate registration number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
          </Row>
        </Form>

        <div>
          <h5>Loan Details</h5>
          <p>Total Payable: {loanDetails.totalPayable}</p>
          <p>Monthly Payable: {loanDetails.monthlyPayable}</p>
          <p>Penalty: {loanDetails.penalty}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={calculateLoan}>
          Calculate
        </Button>
        <Button variant="success" onClick={applyLoan}>
          Apply Loan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoanApplicationModal;
