import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import axios from "axios";
import "./CalendarEvents.css";

const initialValue = dayjs();

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.some((event) => dayjs(event.date).isSame(day, "day"));

  const eventEmoji = highlightedDays.find((event) =>
    dayjs(event.date).isSame(day, "day")
  )?.emoji;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? eventEmoji : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest() {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [currentMonth, setCurrentMonth] = React.useState(initialValue);

  const fetchHighlightedDays = async (date) => {
    try {
      const response = await axios.get(
        " https://kiamabackend.mathenoid.com/api/events"
      );
      setHighlightedDays(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  React.useEffect(() => {
    fetchHighlightedDays(currentMonth);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, [currentMonth]);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    setCurrentMonth(date);
  };

  const addDefaultEvent = (date) => {
    const defaultEvent = {
      date: date.set("date", 12).format("YYYY-MM-DD"),
      name: "contribution Deadline",
      description:
        "This is the monthly contribution deadline deadline that occurs on the 12th of every month.",
      emoji: "📅",
    };
    return defaultEvent;
  };

  const currentMonthEvents = highlightedDays.filter((event) =>
    dayjs(event.date).isSame(currentMonth, "month")
  );
  const defaultEvent = addDefaultEvent(currentMonth);

  if (
    !currentMonthEvents.some((event) =>
      dayjs(event.date).isSame(defaultEvent.date, "day")
    )
  ) {
    currentMonthEvents.push(defaultEvent);
  }

  return (
    <div className="calendar">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          defaultValue={currentMonth}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays: currentMonthEvents,
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
