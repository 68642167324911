import React from "react";
import SideBarNotifications from "../../Components/SideBarNotifications/SideBarNotifications";
const Notifications = () => {
  return (
    <div>
      <SideBarNotifications />
    </div>
  );
};

export default Notifications;
