import React, { useState } from "react";
import { Table } from "react-bootstrap";
import "./NotificationsTable.css";

const NotificationsTable = () => {
  const [notifications, setNotifications] = useState([
    {
      title: "New Feature Release",
      description: "We have released a new feature for better user experience.",
      date: "2024-07-01",
    },
    {
      title: "Maintenance Notice",
      description:
        "Scheduled maintenance on 2024-07-05 from 00:00 to 04:00 UTC.",
      date: "2024-07-03",
    },
    {
      title: "System Update",
      description: "System will be updated with new security patches.",
      date: "2024-07-07",
    },
    {
      title: "New Feature Release",
      description: "We have released a new feature for better user experience.",
      date: "2024-07-01",
    },
    {
      title: "Maintenance Notice",
      description:
        "Scheduled maintenance on 2024-07-05 from 00:00 to 04:00 UTC.",
      date: "2024-07-03",
    },
    {
      title: "System Update",
      description: "System will be updated with new security patches.",
      date: "2024-07-07",
    },
    {
      title: "New Feature Release",
      description: "We have released a new feature for better user experience.",
      date: "2024-07-01",
    },
    {
      title: "Maintenance Notice",
      description:
        "Scheduled maintenance on 2024-07-05 from 00:00 to 04:00 UTC.",
      date: "2024-07-03",
    },
    {
      title: "System Update",
      description: "System will be updated with new security patches.",
      date: "2024-07-07",
    },
    {
      title: "New Feature Release",
      description: "We have released a new feature for better user experience.",
      date: "2024-07-01",
    },
    {
      title: "Maintenance Notice",
      description:
        "Scheduled maintenance on 2024-07-05 from 00:00 to 04:00 UTC.",
      date: "2024-07-03",
    },
    {
      title: "System Update",
      description: "System will be updated with new security patches.",
      date: "2024-07-07",
    },
    {
      title: "New Feature Release",
      description: "We have released a new feature for better user experience.",
      date: "2024-07-01",
    },
    {
      title: "Maintenance Notice",
      description:
        "Scheduled maintenance on 2024-07-05 from 00:00 to 04:00 UTC.",
      date: "2024-07-03",
    },
    {
      title: "System Update",
      description: "System will be updated with new security patches.",
      date: "2024-07-07",
    },
  ]);

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Notifications</h2>
      <div className="table-container">
        <Table className="table">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Title</th>
              <th>Description</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{notification.title}</td>
                <td>{notification.description}</td>
                <td>{notification.date}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default NotificationsTable;
