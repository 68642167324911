import React from "react";
import "./VirtualAssistantInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

const VirtualAssistantInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />

      <div className="mainVirtualAssistantDiv">
        <div className="virtualAssistantGuideVideo">
          <iframe
            width="1000px"
            height="540px"
            title="admin guide"
            src="https://video.recordonce.com/embed/LPAAfdWD5gh"
            frameborder="0"
            allow="accelerometer;fullscreen; downloads; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Box>
  );
};

export default VirtualAssistantInitial;
