import React from "react";
import "./Shares.css";
import SideBarShares from "../../Components/SideBarShares/SideBarShares";

const Shares = () => {
  return (
    <>
      <SideBarShares />
    </>
  );
};

export default Shares;
