import React, { useContext, useState, useEffect } from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./DashboardInitial.css";
import Card from "../../Components/Card/Card";
import DP from "../../Assets/Images/person.png";
import CalendarEvents from "../../Components/CalendarEvents/CalendarEvents";
import { Typography } from "@mui/material";
import axios from "axios";
import UserContext from "../../contexts/UserContext";

const DashboardInitial = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [totalLoans, setTotalLoans] = useState(0);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          " https://kiamabackend.mathenoid.com/api/events"
        );
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
        setError(error.message);
      }
    };

    const fetchTotalLoans = async () => {
      try {
        const response = await axios.get(
          ` https://kiamabackend.mathenoid.com/api/member-loans/${user}`
        );
        const loans = response.data.loans;
        const total = loans.reduce(
          (acc, loan) => acc + parseFloat(loan.amount),
          0
        );
        setTotalLoans(total);
      } catch (error) {
        console.error("Error fetching total loans:", error);
        setError(error.message);
      }
    };

    fetchEvents();
    fetchTotalLoans();
  }, [user]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="dashboardContents">
        <div className="leftDashboardContents">
          <div className="name">Dashboard</div>
          <div className="welcome">
            <div className="welcomeRight">
              <span className="title">Welcome back {user}</span>
              <p className="welcomeText">
                We're delighted to have you. Need help on system walk through?
                Navigate to virtual assistant on the side menu
              </p>
            </div>
            <div className="welcomeImg">
              <img src={DP} alt="nyagah" width="70px" height="70px" />
            </div>
          </div>
          <div className="eventsBox">
            <div className="eventTitleCalendar">
              <div className="eventTitle">
                <p className="title">Events</p>
              </div>
              <div className="calendar">
                <CalendarEvents events={events} />
              </div>
            </div>

            <div className="key">
              {events.map((event) => (
                <div key={event.id} className="keyElement">
                  {event.emoji} {event.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="rightDashboardContents">
          <Card name="Arrears" number="25000" />
          <Card name="Loans" number={totalLoans} />
          <Card name="Shares" number="23" />
        </div>
      </div>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{
          color: "#69c37b",
          fontWeight: "bolder",
          fontSize: "15px",
        }}
      >
        Welcome {user}
      </Typography>{" "}
      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{
            marginTop: 2,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DashboardInitial;
