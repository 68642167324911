import React from "react";
import "./SharesInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SharesTable from "../../Components/SharesTable/SharesTable";

const SharesInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainDiv">
        <div className="titleDiv">
          <p className="loansTitle">my shares</p>
        </div>
        <div className="sharesTableDiv">
          <SharesTable />
        </div>
        <div className="sharesBuyShareDiv">
          <button className="btn btn-md btn-success">Buy Shares</button>
          <button className="btn btn-md btn-success">Sell Shares</button>
        </div>
      </div>
    </Box>
  );
};

export default SharesInitial;
