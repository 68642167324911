import React, { useState } from "react";
import "./LoansInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import LoansTable from "../LoansTable/LoansTable";
import LoanApplicationModal from "../LoanApplicationModal/LoanApplicationModal";
import Button from "react-bootstrap/Button";

const LoansInitial = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainLoansDiv">
        <div className="loanApplication">
          <Button variant="primary" onClick={handleShow}>
            Apply New Loan
          </Button>

          <LoanApplicationModal
            show={show}
            handleClose={handleClose}
            className="mt-3"
          />
        </div>

        <div className="loansTable">
          <LoansTable />
        </div>
      </div>
    </Box>
  );
};

export default LoansInitial;
