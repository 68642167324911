import React from "react";
import "./Repository.css";
import SideBarRepository from "../../Components/SideBarRepository/SideBarRepository";

const Repository = () => {
  return (
    <div>
      <SideBarRepository />
    </div>
  );
};

export default Repository;
