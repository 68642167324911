import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NotificationsTable from "../NotificationsTable/NotificationsTable";
import "./NotificationsInitial.css";

const NotificationsInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <NotificationsTable />
    </Box>
  );
};

export default NotificationsInitial;
