import React from "react";
import "./Logo.css";
import Logos from "../../Assets/Images/person.png";
const Logo = () => {
  return (
    <div className="centerLogo">
      <img className="centerImage " src={Logos} alt="logo" />
    </div>
  );
};

export default Logo;
